<template>
  <div
    class="IconRadio"
    :class="{ isChecked, isInvalid }"
    :style="computedRadioStyle">
    <span
      class="__bubble"
      :style="computedBubbleStyle">
    </span>
  </div>
</template>


<script>
export default {
  name: 'IconRadio',

  props: {
    isChecked: {
      type: Boolean,
      default: false,
    },
    isInvalid: {
      type: Boolean,
      default: false,
    },
    size: {
      type: [String, Number],
      default: 18,
    },
    color: {
      type: String,
      default: 'currentColor',
    },
  },

  computed: {
    computedRadioStyle() {
      return {
        width: `${this.size}px`,
        height: `${this.size}px`,
        borderColor: this.color,
      };
    },

    computedBubbleStyle() {
      return {
        width: `${Math.floor(this.size * 0.6)}px`,
        height: `${Math.floor(this.size * 0.6)}px`,
        background: this.color,
      };
    },
  },
};
</script>


<style lang="scss" scoped>
@import '@/styles/_variables';

.IconRadio {
  position: relative;
  border-radius: 50%;
  border: $border;
}

.__bubble {
  @include absolute;
  display: block;
  border-radius: 50%;
  background: $light-blue;
  transform: scale(0);
}

.IconRadio:not(.isChecked) {
  border-color: $border-color !important;
}

.IconRadio.isInvalid {
  border-color: $red !important;
}

.IconRadio.isChecked {
  .__bubble {
    transform: scale(1);
  }
}
</style>
